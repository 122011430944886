// import moment from "moment";

// export const TimeAgo = props => {
//     const date = moment(props.children).fromNow();
//     return <div>{date}</div>;
// };

// export const DateFormat = props => {
//     return moment(props.children).format("l");
// };
export const Currency = (number) => {
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 2
    });
    if (isNaN(number)) return "";
    const dollars = formatter.format(number);
    return `<span>${dollars}</span>`
};

export const NumberFormat = number => {
    if (!number) return "";
    const formatter = new Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0
    });
    return formatter.format(number);
};