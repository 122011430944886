import { $q, $$, on, append, changeClasses } from "../dom/events.js";
import { DOMAIN, graphFetch } from "../dom/network.js";
import {
  createCCForm,
  CREATE_INTENT,
  payWithCard,
} from "../components/stripe.js";
import { Currency } from "../../components/formatters.js";

const POST_CONTACT = `
  mutation saveLead($lead: LeadInput)
  {
    saveLead(lead: $lead)
    {
      id
    }
  }
`;

const success = (apiRes) => {
  const el = $q(".contact");
  changeClasses(el, "contact successful");
};

let tempText;
const sending = () => {
  tempText = $q(".submit").value;
  $q(".submit").value = "Sending...";
  changeClasses($q(".contact"), "contact sending");
};

const failure = (apiRes) => {
  changeClasses($q(".contact"), "contact failure");
  $q(".submit").value = tempText || "Send Message";
};

const reset = () => {
  changeClasses($q(".contact"), "contact");
};

const validate = (values) => {
  let missed = [];
  const requires = ["name", "email", "message"];
  for (key of requires) {
    const k = `${key}-bounds`;
    if (values[key].trim() === "") {
      missed.push(key);
      $$(`.${k}`).map((el) => changeClasses(el, `${k} invalid`));
    } else {
      $$(`.${k}`).map((el) => changeClasses(el, k));
    }
  }
  return missed;
};

const fetchIntent = async (amount) => {
  return await graphFetch(CREATE_INTENT, { amount });
};

let ccIntent, ccElement;
const paymentFormSubmit = async (event) => {
  event.preventDefault();
  const amount = $q("#amount").value;
  if (parseFloat(amount) === 0) return;
  const resp = await fetchIntent(parseFloat(amount) * 100 + "");
  ccIntent = resp.data.stripeCreateIntent.clientSecret;
  if (ccIntent) {
    toggleForms();
    append($q(".amount-js"), Currency(amount));
    $q(".contact-form .toggle-forms").classList.add("approved");
  }
};

const contactFormSubmit = async (event) => {
  event.preventDefault();
  const form = event.target;
  let inputs = form.querySelectorAll(".name, .email, .message");
  inputs = Array.from(inputs);
  const values = {};
  let name;
  for (let i in inputs) {
    name = inputs[i].name.replace("cta-", "");
    values[name] = inputs[i].value;
  }
  const missed = validate(values);
  if (missed.length !== 0) {
    for (let j in missed)
      $q("." + missed[j])
        .closest(".input-bounds")
        .classList.add("invalid");
    return;
  }
  sending();
  try {
    values.portal = "ContactForm";
    values.message = `Message:\n${values.message}`;
    // console.log("CC", ccIntent, ccElement)
    const name = $q(".contact-form .name").value;
    if (ccIntent && ccElement) {
      const amount = $q("#amount").value;
      const email = $q(".contact-form .email").value;
      const payResponse = await payWithCard(ccElement, ccIntent);
      values.amount = amount;
      values.token = ccIntent;
    }
    const resp = await graphFetch(POST_CONTACT, { lead: values });
    if (resp.data.saveLead && resp.data.saveLead.id) success();
    else failure();
  } catch (e) {
    console.error(e);
    failure();
  }
};

const toggleForms = () => {
  const cs = "contact-state";
  const ps = "payment-state";
  const pt = $q(".payToggle").classList;
  const cl = Array.from(pt);
  // console.log(cl)
  const payYes = cl.includes(cs);
  $$(".input-bounds").map((i) => i.classList.remove("invalid"));
  if (payYes) {
    pt.remove(cs);
    pt.add(ps);
    if ($q("#card-element") && Stripe) {
      ccElement = createCCForm("#card-element");
    }
  } else {
    pt.add(cs);
    pt.remove(ps);
  }
};

const load = (e) => {
  on("submit", ".contact-form", contactFormSubmit);
  on("submit", ".payment-form", paymentFormSubmit);
  on("click", ".toggle-forms", toggleForms);
};

load();
