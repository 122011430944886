let D;
switch (process.env.NODE_ENV) {
    case "production":
        D = "https://api.lawrencewhiteside.com";
        break;
    case "staging":
        D = "https://stgapi.lawrencewhiteside.com";
        break;
    default:
        D = "http://api.localhost";
}
// console.log("DOMAIN", D)
export const DOMAIN = D;

export const post = async (url, body) => {
    try {
        const response = await fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(body),
        });
        return await response.json();
    } catch (e) {
        console.error(e);
    }
};

export const postUpload = async (url, formData) => {
    try {
        const response = await fetch(DOMAIN + url, {
            method: "POST",
            headers: {
                Accept: "application/json",
            },
            credentials: "include",
            body: formData,
        });
        return await response.json();
    } catch (e) {
        console.error(e);
    }
};

const GRAPHQL_URL = `${DOMAIN}/graphql`;
export const graphFetch = async (query, variables = {}, cookie = "") => {
    try {
        const r = await fetch(GRAPHQL_URL, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                cookie,
            },
            credentials: "include",
            body: JSON.stringify({
                query,
                variables,
            }),
        });
        return await r.json();
    } catch (e) {
        return e;
        console.error(e);
    }
};
