export const payWithCard = async (card, clientSecret, obj) => {
    return await window.stripe.confirmCardPayment(clientSecret, {
        payment_method: {
            card: card,
            ...obj
        },
    })
}

export const createCCForm = (id) => {
    const elements = window.stripe.elements();
    const cardElement = elements.create('card');
    cardElement.mount(id);
    cardElement.on('change', (event) => {
        var displayError = document.getElementById('card-errors');
        if (event.error) {
            displayError.textContent = event.error.message;
        } else {
            displayError.textContent = '';
        }
    });
    return cardElement;
}

export const CREATE_INTENT = `
    mutation stripeCreateIntent(
        $amount: String
        $items: [String]
    ) {
    stripeCreateIntent(
        amount: $amount
        items: $items
    ) {
        clientSecret
        amount
    }
}
`

export const confirmCard = async (clientSecret, card) => {
    const resp = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
            card
        }
    })
}

